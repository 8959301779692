import React, { useState, useEffect } from "react"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import AltHeader from "../components/AltHeader"
import TextPathDrawing from "../components/textPathDrawing";
import Textures from "../components/Textures";
import TeamBlock from "../components/TeamBlock"
import TeamBlockButton from "../components/TeamBlockButton"
import {
  Andrew,
  Matt,
  Ashley,
  Adan,
  Victoria } from "../components/TeamImages"

export default function About(props) {
  const pages = [
    {
      url: "/about",
      name: "About"
    },
    {
      url: "/work",
      name: "Work"
    },
    {
      url: "/",
      name: "Home"
    }
  ]
  const team = [
    {
      name: "Andrew Averso",
      title: "Andrew Averso: Creative Director",
      bio: "As a well-rounded, multi-disciplinary creative Andrew leads the direction of brand + design. His work, spanning creative direction, art direction, design and photography has appeared in Vogue, WWD, W. Magazine, Refinery29 and Bloomingdale’s.",
      image: Andrew
    },
    {
      name: "Matthew Power",
      title: "Matthew Power: Web Director",
      bio: "As lead developer, Matt ensures that clients are getting high quality websites that accurately portray their brand. He has spent years both designing and building websites for clients. Matt believes that web experiences should be delightful and familiar while still pushing boundaries and he works to make that true of every website he builds.",
      image: Matt
    },
    {
      name: "Ashley Pollard",
      title: "Ashley Pollard: Front End Web Developer",
      bio: "Ashley is an artist of many trades -- a game developer, web developer, illustrator, and painter. She creates websites that focus on the user and the micro-interactions that elevate the experience. She's created interactive media for PBS, Sesame Street, The Museum of Design Atlanta (MODA), and Jim Henson. Development is a creative path for her to make cool things that inspire, educate and defy our expectations of technology.",
      image: Ashley
    },
    {
      name: "Adán Bean",
      title: "Adán Bean: Creative Director",
      bio: "An introvert with an extrovert’s job, Adán Bean puts words together in all facets of creative life. With over a decade in digital marketing and advertising, he has served as editor-in-chief for various online properties spanning from music to culture sites. His work has been featured on or with brands such as TEDx, Habitat for Humanity, Def Jam Recordings, Cash Money Records, The Bert Show, Chick-fil-A, CarMax, Coca-Cola, Cricket Wireless, Delta Airlines, All Def Digital, and more.",
      image: Adan
    },
    {
      name: "Victoria Vasquez",
      title: "Victoria Vasquez: Senior Graphic Designer + Brand Specialist",
      bio: "As the Lead Designer and Brand Specialist at Oust, Victoria loves building brands from the ground up and crafting unique visual identities that suit each client's story and needs. Her work spans design, art direction, photography, and styling.",
      image: Victoria
    } 
  ]

  const [ openElement, setOpenState ] = useState(null)
  const [isMobile, setIsMobile] = useState(null)

  const screenCheck = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    screenCheck()
    window.addEventListener('resize', () => {screenCheck()})
  })

  useEffect(() => {
    if (!isMobile) {
      let close = () => {
        if (openElement) {
          setOpenState(null)
          window.removeEventListener("click", close)
        }
      }
  
      window.addEventListener("click", close)
    }
  })

  return (
    <main>
      <SEO title="About" />
      <main className="min-h-screen w-screen bg-primary">
        <Textures/>
        <AltHeader pages={pages}/>
        <section
          className="uppercase p-4 px-6 flex items-center flex-col lg:block lg:absolute right-0"
          style={{top: "11em"}}
          css={css`
            @media screen and (min-width: 1024px) {
              margin-right: 3%;
            }
          `
        }> 
          <p className="text-2xl lg:text-3xl text-center lg:text-left max-w-xl z-0 mb-4 lg:mb-16">
            Wild Places is an identities company and branding studio based in Atlanta, Georgia.
          </p>
        </section>
        <section className="lg:absolute flex flex-col md:items-center lg:block p-4" style={{top: "33%"}}
          css={css`
            @media screen and (min-width: 1024px) {
              margin-left: 11%;
            }
          `
        }>
          <h2 className="uppercase text-4xl mb-8 text-center lg:text-left z-0">The Team</h2>
          <ol className="w-full lg:mt-12 lg:ml-40 text-center lg:text-left"
            css={css`
              @media screen and (min-width: 768px) {
                max-width: 460px;
              }
            `
          }>
          
            {team.map((m, index) => {
              return (
                <li
                  key={index}
                  className="relative mb-1 z-1"
                  style={{zIndex: openElement ? "unset" : "1"}}
                >
                  <TeamBlockButton openElement={openElement} setOpenState={setOpenState} />
                  <h3 className="uppercase inline text-2xl ">{m.name}</h3>
                  <TeamBlock isMobile={isMobile} openElement={openElement} setOpenState={setOpenState} content={m}/>
                </li>
              )
            })}
          </ol>
        </section>
        <div className="drawing-wrapper hidden lg:block">
          <TextPathDrawing />
        </div>
      </main>
    </main>
  )
}
